import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { PermissionService } from 'app/main/apps/permission/permisson.service';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;

  // Private
  private _unsubscribeAll: Subject<any>;
  permissions: any = {};


  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef,
              private _coreMenuService: CoreMenuService,
              private permissionService: PermissionService) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();
    this.permissions = this.permissionService.permissions
    this.currentUser = this._coreMenuService.currentUser;
    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._coreMenuService.currentUser;
      let menu = this._coreMenuService.getCurrentMenu();
      this.menu = this.filterMenuItems(menu)
      this._changeDetectorRef.markForCheck();
    });
    this.permissionService.getCurrentUserPermissions().subscribe((permissions)=>{
      this.permissions = permissions
      let menu = this._coreMenuService.getCurrentMenu();
      this.menu = this.filterMenuItems(menu)
      this._changeDetectorRef.markForCheck();
    })
  }
  
  filterMenuItems(menuItems) {
    if (!this.currentUser) {
      return
    }
    let items = JSON.parse(JSON.stringify(menuItems))
    let selectedItems = items.filter(item => {
      if(item.children) {
        item.children = this.filterMenuItems(item.children)
      }
      
      if (item.exclude_permission) {
        return item.role ? item.role.includes(this.currentUser.role) : false || item.role == undefined
      }
      if (!item.permission) {
        return true
      }
      else {
        return this.permissions[item.permission]
      }
    })
    return selectedItems
  }
  shouldShow(item, permissions) {
    if (item.exclude_permission) {
      return item.role ? item.role.includes(this.currentUser.role) : false || item.role == undefined
    }
    else {
      return permissions[item.permission]
    }
  }
}
